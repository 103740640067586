import {includes} from 'lodash';

export const getBrowserLocale = () => {
    let locale = 'en';
    const browserLocale = require('browser-locale')();

    if (browserLocale !== '' && includes(browserLocale, '-')) {
        locale = browserLocale.split('-')[0];
    }
    else if (browserLocale !== '' && browserLocale.length === 2) {
        locale = browserLocale;
    }
    return locale;
};

export const locales = {
    nl: {
        code: 'nl',
        description: 'Nederlands',
    },
    fr: {
        code: 'fr',
        description: 'Français',
    },
    en: {
        code: 'en',
        description: 'English',
    }
};