import React, { Component } from "react";
import { IntlPicker, withGetText, IntlConsumer } from "./intl";

export default class Footer extends Component {
  render() {
    const { gettext } = this.props.localization;
    const currentYear = new Date().getFullYear();
    return (
      <section className="g-bg-gray-dark-v3 g-py-2">
        <div className="container">
          <div className="row d-flex justify-content-between align-items-center">
            <div className="col-sm-5">
              <img
                className="img-fluid w-25"
                src={require("./assets/img/main_logo.png")}
                alt="home"
              />
            </div>
            <p className="g-font-size-14 g-color-white mb-0 align-items-center">
              <IntlConsumer>
                {({ locale }) => {
                  let url;
                  switch (locale.code) {
                    case "nl":
                      url =
                        "https://www.dieteren.be/media/rx0pzkmh/wettelijke-informatie.pdf";
                      break;
                    case "fr":
                      url =
                        "https://www.dieteren.be/media/u4sb35jt/informations-l%C3%A9gales.pdf";
                      break;
                    case "en":
                      url =
                        "https://www.dieteren.be/media/11xbtwkq/informations-l%C3%A9gales_en.pdf";
                      break
                    default:
                      url =
                        "https://www.dieteren.be/media/u4sb35jt/informations-l%C3%A9gales.pdf";
                  }

                  return (
                    <a
                      href={url}
                      className="u-tabs-v1__link g-font-size-14 g-color-white align-self-center"
                    >
                      {gettext("Legal regulations")}
                    </a>
                  );
                }}
              </IntlConsumer>

              <span className="g-py-10"> | </span>
              {`D'Ieteren Automotive SA/NV © ${currentYear}`}
            </p>
          </div>
        </div>
      </section>
    );
  }
}
