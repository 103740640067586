import React from 'react';
import {IntlContext} from './IntlContext'
import {locales as allLocales} from '../common';
import Picker from './picker/Picker';

export default () => {
    return (
        <IntlContext.Consumer>
            {({locale, locales, setLocale}) =>
                <Picker renderTitle={() => (
                    <span>
                        {locale.code.toUpperCase()} <i className="fa fa-angle-down g-ml-5 g-font-size-15"></i>
                    </span>
                )}>
                    <ul className="list-inline mb-0 g-min-width-150 g-color-text ">
                        {locales.map((locale, i) =>
                            <li key={locale} className="list-inline-item row g-ma-0 g-pa-0 clickable"
                                onClick={() => setLocale(locale)}>
                                > {allLocales[locale].description} ({locale.toUpperCase()})
                            </li>
                        )}
                    </ul>
                </Picker>
            }
        </IntlContext.Consumer>
    );
};