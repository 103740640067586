import React from 'react';
import {IntlContext} from './IntlContext';
import {locales, getBrowserLocale} from '../common';


class IntlProvider extends React.Component {
    constructor(props) {
        super(props);

        const defaultLocale = this.getDefaultLocale(props.defaultLocale);

        this.state = {
            currentLocale: defaultLocale,
            messages: this.getTranslations(defaultLocale),
        };

    }

    setLocale = (locale) => {
        this.setState({
                currentLocale: locale,
                messages: this.getTranslations(locale),
            }
        );
    };

    getDefaultLocale = (locale) => {
        let current = locale;
        if(locale === 'browser'){
            current = getBrowserLocale();
        }
        return Object.prototype.hasOwnProperty.call(locales, current)
            ? current
            : 'en';
    };

    getTranslations = (locale) => {
        try {
            return  require(`../../translations/${locale}.json`);
        }
        catch (err) {
            return [];
        }
    };

    getText = (text) => {
        debugger;
        const {messages} = this.state;
        return Object.prototype.hasOwnProperty.call(messages, text)
            ? messages[text]
            : text;
    };

    render() {
        const data = {
            locale: locales[this.state.currentLocale],
            locales: [...this.props.locales],
            setLocale: this.setLocale,
            getText: this.getText,
        };

        return (
            <IntlContext.Provider value={data}>
                {this.props.children}
            </IntlContext.Provider>
        );
    }
}

IntlProvider.defaultProps = {
    defaultLocale: 'en',
    locales: ['en'],
};

export default IntlProvider;