import React from 'react';
import CookieConsent from "react-cookie-consent";
import { withGetText} from './intl';

class GDPR extends React.Component {
    render() {
        const {gettext} = this.props.localization;

        const gdpr_cookie = "dieteren-gdpr-cookie";

        return (
            <CookieConsent
                debug={false}
                location="bottom"
                buttonText={gettext('Accept cookies')}
                cookieName={gdpr_cookie}
                style={{background: "#2B373B"}}
                buttonStyle={{backgroundColor: "white", color: 'black', fontSize: "13px", margin: 30}}
                contentStyle={{margin: 30}}
                expires={150}
            >
                {gettext("This website uses cookies. We use cookies to make our website work at the best, improving your online experience.")}<br/>
                {gettext(" By continuing to navigate on this site, you agree to the use of cookies.")} <span> </span>
                <a href="https://www.usedcars.center/privacy" className='g-text-underline clickable'>{gettext("Learn more")}</a>

            </CookieConsent>
        )
    }
}

export default withGetText(GDPR);