import React from 'react';
export const IntlContext = React.createContext();

export function withGetText(Component) {
    return function WrapperComponent(props) {
        return (
            <IntlContext.Consumer>
                {({getText}) => <Component {...props} localization={{gettext: getText}} />}
            </IntlContext.Consumer>
        );
    };
}