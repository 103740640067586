import React, {Component} from 'react';
import CountUp from 'react-countup';
import {IntlPicker, withGetText, IntlConsumer} from './intl';
import GDPR from './GDPR';
import Footer from './Footer';
class App extends Component {

    render() {
        const {gettext} = this.props.localization;
        return (
            <main >

                {/* HEADER */}
                <header id="js-header"
                        className="u-header u-header--sticky-top">
                    <div className="u-header__section u-shadow-v27 g-bg-white">
                        <nav className="navbar navbar-expand-lg py-0 g-px-15">
                            <div className="container g-pos-rel">

                                <div className="navbar-brand u-header__logo">
                                    <img className="u-header__logo-img u-header__logo-img--main "
                                         src={require("./assets/img/logov2.jpg")} alt="logo"/>
                                </div>

                                <div id="navBar" className="navbar-collapse g-hidden-md-down">
                                    <div
                                        className="navbar-collapse align-items-center g-mr-20 ">
                                        <ul
                                            className="navbar-nav g-flex-right--xs text-uppercase w-100 g-font-weight-700 g-font-size-11 g-pt-20 g-pt-0--lg">
                                            <li className="nav-item g-mr-15--lg g-mb-12 g-mb-0--lg active">
                                                <a href="#home" className="nav-link p-0">{gettext('Home')}</a>
                                            </li>
                                            <li className="nav-item g-mx-15--lg g-mb-12 g-mb-0--lg">
                                                <a href="#benefits" className="nav-link p-0">{gettext('Benefits')}</a>
                                            </li>
                                            <li className="nav-item g-mx-15--lg g-mb-12 g-mb-0--lg">
                                                <a href="#whyOurApp" className="nav-link p-0">{gettext('Why us')}</a>
                                            </li>
                                            <li className="nav-item g-ml-15--lg g-mb-12 g-mb-0--lg">
                                                <a href="#contact" className="nav-link p-0">{gettext('Contact')}</a>
                                            </li>
                                        </ul>
                                    </div>

                                  <IntlPicker />

                                    <div
                                        className="menu-item g-color-white g-bg-primary g-bg-primary-dark-v1--hover g-font-weight-500 g-px-15 g-py-6 g-ml-10">
                                        <IntlConsumer>
                                            {({locale,}) =>
                                                <a href={`https://usedcars.center/login?culture=${locale.code}`}>
                                                    <span className="g-color-white">{gettext('Log in')}</span>
                                                </a>
                                            }
                                        </IntlConsumer>
                                    </div>
                                </div>

                                <div className="g-hidden-lg-up" style={{
                                    float: 'left',
                                    display: 'flex',
                                    flex: 1,
                                    justifyContent: 'flex-end'
                                }}>
                                    <IntlPicker/>

                                    <div
                                        className="menu-item g-color-white g-bg-primary g-bg-primary-dark-v1--hover g-font-weight-500 g-px-15 g-py-6 g-ml-10">
                                        <IntlConsumer>
                                            {({locale,}) =>
                                                <a href={`https://usedcars.center/login?culture=${locale.code}`}>
                                                    <span className="g-color-white">{gettext('Log in')}</span>
                                                </a>
                                            }
                                        </IntlConsumer>
                                    </div>
                                </div>


                            </div>
                        </nav>
                    </div>
                </header>

                {/* HOME */}
                <section id="home" className="g-theme-bg-gray-light-v1 g-pt-170--lg g-pt-70 g-mb-60">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 ml-lg-auto d-flex text-center text-md-left g-font-size-16">
                                <div className="align-self-center">
                                    <h2 className="text-uppercase g-line-height-1_3 g-font-size-28 g-mb-10">
                                        {gettext('Online auction platform for used cars of the official dealer network VW, AUDI, SEAT, SKODA & PORSCHE.')}
                                    </h2>
                                    <p className="g-mb-20">{gettext('Daily new lists of takeover cars from the D\'ieteren dealer network.')}
                                        <br />
                                        <span> </span> {gettext('Easily receive price requests with the web version or the application.')}
                                        <br />
                                        <span> </span> {gettext('You choose which concessions you wish to work with and set up your filters to receive a tailor-made offer.')}
                                        <br />
                                    </p>

                                    <strong>{gettext('Register today!')}</strong>

                                    <div className="row g-pa-0 g-ma-0 g-mt-10 justify-content-center justify-content-md-start justify-content-lg-start justify-content-xl-start ">


                                            <IntlConsumer>
                                                {({locale,}) =>
                                                    <a href={`https://usedcars.center/login?culture=${locale.code}`}
                                                       className="btn btn-md g-color-white g-bg-primary g-bg-primary-dark-v1--hover g-font-weight-700 text-uppercase text-center g-py-10 g-mb-15 g-mx-10 g-mx-0--md g-mr-30--md">
                                                         <span className="g-font-size-13">
                                 {gettext('I\'m a trader')}
                                  </span>
                                                    </a>
                                                }
                                            </IntlConsumer>



                                        {/*<a href={'https://admin.usedcars.center'}*/}
                                        {/*   target="_blank" rel="noopener noreferrer"*/}
                                        {/*   style={{backgroundColor: 'gray'}}*/}
                                        {/*   className="btn btn-md g-color-white g-bg-gray-dark-v4--hover g-font-weight-700 text-uppercase text-center g-py-10 g-mb-15 g-mx-10 g-mx-0--md g-mr-10 g-mr-30--md ">*/}
                                        {/*    <span className="g-font-size-13">*/}
                                        {/*        {gettext('I\'m a dealer')}*/}
                                        {/*    </span>*/}
                                        {/*</a>*/}


                                        {/*<a href={'https://itunes.apple.com/us/app/dieteren-trader/id1282966903?mt=8'}*/}
                                           {/*target="_blank"*/}
                                           {/*rel="noopener noreferrer"*/}
                                           {/*className="btn btn-md u-btn-primary g-font-weight-700 text-uppercase text-center g-py-10 g-mb-15 g-mx-10 g-mx-0--md g-mr-30--md ">*/}
                                            {/*<i className="fa fa-apple float-left g-font-size-26"></i>*/}
                                        {/*</a>*/}


                                        {/*<a href={'https://play.google.com/store/apps/details?id=com.autralis.dieteren.trader'}*/}
                                           {/*target="_blank"*/}
                                           {/*rel="noopener noreferrer"*/}
                                           {/*className="btn btn-md u-btn-primary g-font-weight-700 text-uppercase text-center  g-py-10 g-mb-15 g-mx-10 g-mx-0--md ">*/}
                                            {/*<i className="fa fa-android float-left g-font-size-26 "></i>*/}
                                        {/*</a>*/}
                                    </div>


                                </div>
                            </div>

                            <div
                                className="col-md-6  g-overflow-hidden d-flex justify-content-center align-content-center align-items-center">
                                <img className="img-fluid" src={require("./assets/img/phone.png")}
                                     alt="home"/>
                            </div>
                        </div>
                    </div>
                </section>

                {/* BENEFITS */}
                <section id="benefits" className="g-py-90 g-bg-white">
                    <div className="container text-center g-max-width-870 g-font-size-16 g-mb-65">
                        <div className="text-uppercase g-line-height-1_3 g-mb-20">
                            <h4 className="g-font-weight-700 g-font-size-11 g-mb-15"><span
                                className="g-color-primary">01. </span> {gettext('Our benefits')}
                            </h4>
                            <h2 className="g-line-height-1_3 g-font-size-36 mb-0">{gettext('Easy in use')} - <strong>{gettext('Consultable everywhere')}</strong>
                            </h2>
                        </div>

                        <p className="mb-0">{gettext('Discover a couple of key benefits from our solution.')}</p>
                    </div>

                    <div className="container">

                        <div className="row">
                            <div className="col-md-6 col-lg-4 g-mb-30">
                                <div className="text-center g-bg-gray-light-v5 g-py-40 g-px-30 g-min-height-250">
                            <span className="u-icon-v1 g-font-size-36 g-color-primary g-mb-30"><i
                                className="fa fa-search"></i></span>
                                    <h4 className="text-uppercase g-font-weight-700 g-font-size-11 g-color-black g-mb-20">{gettext('Quickly find the cars you are looking for')}
                                    </h4>
                                    <p className="mb-0">{gettext('Browse quickly and easily between all type of make/models.')}</p>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-4 g-mb-30">
                                <div className="text-center g-bg-gray-light-v5 g-py-40 g-px-30 g-min-height-250">
                            <span className="u-icon-v1 g-font-size-36 g-color-primary g-mb-30"><i
                                className="fa fa-sliders"></i></span>
                                    <h4 className="text-uppercase g-font-weight-700 g-font-size-11 g-color-black g-mb-20">{gettext('Different filters for a tailor-made offer')}
                                    </h4>
                                    <p className="mb-0">{gettext('Only receive what you are interested in!')}</p>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-4 g-mb-30">
                                <div className="text-center g-bg-gray-light-v5 g-py-40 g-px-30 g-min-height-250">
                            <span className="u-icon-v1 g-font-size-36 g-color-primary g-mb-30"><i
                                className="fa fa-cloud"></i></span>
                                    <h4 className="text-uppercase g-font-weight-700 g-font-size-11 g-color-black g-mb-20">
                                        {gettext('WHEREVER/WHENEVER YOU NEED')}
                                    </h4>
                                    <p className="mb-0">{gettext('Everything was build up to make sure you could easily make your price offers whenever and wherever you are.')}</p>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-4 g-mb-30 g-mb-0--lg">
                                <div className="text-center g-bg-gray-light-v5 g-py-40 g-px-30 g-min-height-250">
                            <span className="u-icon-v1 g-font-size-36 g-color-primary g-mb-30"><i
                                className="fa fa-eye"></i></span>
                                    <h4 className="text-uppercase g-font-weight-700 g-font-size-11 g-color-black g-mb-20">
                                        {gettext('Detailed inspections')}
                                    </h4>
                                    <p className="mb-0">{gettext('Avoid discussions by misleading inspections, our system brings the most transparant and complete inspections on the market.')}</p>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-4 g-mb-30 g-mb-0--md">
                                <div className="text-center g-bg-gray-light-v5 g-py-40 g-px-30 g-min-height-250">
                            <span className="u-icon-v1 g-font-size-36 g-color-primary g-mb-30"><i
                                className="fa fa-comments"></i></span>
                                    <h4 className="text-uppercase g-font-weight-700 g-font-size-11 g-color-black g-mb-20">{gettext('STEP BY STEP COMMUNICATION')}
                                    </h4>
                                    <p className="mb-0">{gettext('Our solution brings you up to date information on the negociation/transaction steps from the dealer.')}</p>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-4">
                                <div className="text-center g-bg-gray-light-v5 g-py-40 g-px-30 g-min-height-250">
                            <span className="u-icon-v1 g-font-size-36 g-color-primary g-mb-30"><i
                                className="fa fa-home"></i></span>
                                    <h4 className="text-uppercase g-font-weight-700 g-font-size-11 g-color-black g-mb-20">{gettext('Complete range of dealerships ')}
                                    </h4>
                                    <p className="mb-0">{gettext('All dealerships of the network are registered in our system.')}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                {/* COUNTERS */}
                <section id="counters" className="g-bg-white">
                    <div className="container text-center  g-font-size-16 g-mb-65">
                        <div className="row justify-content-center text-center g-color-white">

                            <div className="col-md-3 col-6 d-flex justify-content-center g-mb-60">
                                <div
                                    className="d-flex justify-content-center u-counter-v3 g-brd-primary g-bg-primary g-width-150 g-height-150 rounded-circle g-pa-20">
                                    <div className="align-self-center">
                                        <div className="js-counter g-font-size-30 g-font-weight-300">
                                            <CountUp start={0}
                                                     end={82}/>
                                        </div>
                                        <h4 className="h5">{gettext('Car brands')}</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-6 d-flex justify-content-center g-mb-60">
                                <div
                                    className="d-flex justify-content-center u-counter-v3 g-brd-primary g-bg-primary g-width-150 g-height-150 rounded-circle g-pa-20">
                                    <div className="align-self-center">
                                        <div className="js-counter g-font-size-30 g-font-weight-300">
                                            <CountUp start={0}
                                                     end={10000}/>+
                                        </div>
                                        <h4 className="h5">{gettext('Monthly car requests')}</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3 col-6 d-flex justify-content-center g-mb-60">
                                <div
                                    className="d-flex justify-content-center u-counter-v3 g-brd-primary g-bg-primary g-width-150 g-height-150 rounded-circle g-pa-20">
                                    <div className="align-self-center">
                                        <div className="js-counter g-font-size-30 g-font-weight-300">
                                            <CountUp start={0}
                                                     end={20}/>
                                        </div>
                                        <h4 className="h5">{gettext('Years experience')}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* WHY US */}
                <section id="whyOurApp" className="g-theme-bg-gray-light-v1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 d-flex g-mb-30 g-mb-0--lg">
                                <div className="align-self-center">
                                    <div className="text-uppercase g-line-height-1_3 g-mb-20">
                                        <h4 className="g-font-weight-700 g-font-size-11 g-mb-15">
                                            <span className="g-color-primary">02. </span>{gettext('Why us')}</h4>
                                        <h2 className="g-line-height-1_3 g-font-size-36 mb-0">{gettext('Register now and receive immediately cars.')}
                                        </h2>
                                    </div>

                                    <p className="g-font-size-16 g-mb-20">
                                        {gettext('Direct access to the vehicles offered by our dealers')}
                                    </p>
                                    <div className="u-accordion">

                                        <div className="card rounded-0 g-bg-primary g-color-white g-brd-none">
                                            <div id="awesomeFeatures" className="u-accordion__header g-pa-20">
                                                <h5 className="mb-0 text-uppercase g-font-weight-700">
                                                    <div className="g-color-white g-text-underline--none--hover">
                                            <span className="u-accordion__control-icon g-mr-10">
                                              <i className="fa fa-plus"></i>
                                            </span>
                                                        {gettext('User-friendly features')}
                                                    </div>
                                                </h5>
                                            </div>
                                            <div className="u-accordion__body g-pa-0-20-20">
                                                {gettext('To the point!')} <br />
                                                {gettext('Based on your feedback, we will further develop this tool.')}
                                            </div>
                                        </div>

                                        <div className="card rounded-0 g-bg-primary-dark-v2 g-color-white g-brd-none">
                                            <div className="u-accordion__header g-pa-20">
                                                <h5 className="mb-0 text-uppercase g-font-weight-700">
                                                    <div className="g-color-white g-text-underline--none--hover">
                                              <span className="u-accordion__control-icon g-mr-10">
                                                <i className="fa fa-plus"></i>
                                              </span>
                                                        {gettext('Modern and creative design')}
                                                    </div>
                                                </h5>
                                            </div>
                                            <div>
                                                <div
                                                    className="u-accordion__body g-pa-0-20-20">{gettext('Web based or App based, use it the way you want!')}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card rounded-0 g-bg-primary g-color-white g-brd-none">
                                            <div className="u-accordion__header g-pa-20">
                                                <h5 className="mb-0 text-uppercase g-font-weight-700">
                                                    <div className="g-color-white g-text-underline--none--hover">
                                              <span className="u-accordion__control-icon g-mr-10">
                                                <i className="fa fa-plus"></i>
                                              </span>
                                                        {gettext('Regular updates')}
                                                    </div>
                                                </h5>
                                            </div>
                                            <div>
                                                <div className="u-accordion__body g-pa-0-20-20">
                                                    {gettext('Our solutions are weekly revised in order to get you as productive as can be!')}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card rounded-0 g-bg-primary-dark-v2 g-color-white g-brd-none">
                                            <div className="u-accordion__header g-pa-20">
                                                <h5 className="mb-0 text-uppercase g-font-weight-700">
                                                    <div className="g-color-white g-text-underline--none--hover">
                                              <span className="u-accordion__control-icon g-mr-10">
                                                <i className="fa fa-plus"></i>
                                              </span>
                                                        {gettext('24/7 professional support')}
                                                    </div>
                                                </h5>
                                            </div>
                                            <div>
                                                <div className="u-accordion__body g-pa-0-20-20">
                                                    {gettext('A dedicated team will help you out in finding the best ways to use our solution.')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-5 text-center g-overflow-hidden">
                                <img className="img-fluid" src={require("./assets/img/phone2.png")}
                                     alt="why our app"/>
                            </div>
                        </div>
                    </div>
                </section>

                {/* CONTACT */}
                <section id="contact" className="g-bg-primary g-py-90">
                    <div className="container">
                        <div className="row">
                            <div className="g-mb-20 g-mb-0--lg g-pl-10 g-pr-10">
                                <div className="text-uppercase g-mb-20">
                                    <h4 className="g-font-weight-700 g-font-size-11 g-color-white g-mb-15">
                                        03. {gettext('Contact us')}</h4>
                                    <h2 className="g-font-size-36 g-color-white mb-0">{gettext('Answers to')}
                                        <span> </span>
                                        <strong>{gettext('your questions')}</strong>
                                    </h2>
                                </div>

                                <p className="g-font-size-16 g-color-white mb-0">{gettext('If you have a problem do not hesitate to contact our helpdesk.')}</p>
                                <p className="g-font-size-16 g-color-white mb-0">
                                    Helpdesk : support@usedcars.center - NL : +32 (0)9 320 00 23 - FR : +32 (0)9 320 00
                                    33 - WhatsApp : +32 (0)472 06 01 40
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

            <GDPR />
            <Footer localization={{gettext: gettext}}/>
            </main>
        );
    }
}
export default withGetText(App);
